export function hamburger() {
  const hamburgerTrigger = document.querySelector('.js-hamburgerTrigger');
  const headerNav = document.querySelector('.js-headerNav');
  const bodyEle = document.querySelector('body');

  hamburgerTrigger.addEventListener('click', () => {
    if (hamburgerTrigger.classList.contains('is-active')) {
      hamburgerTrigger.classList.remove('is-active');
      headerNav.classList.remove('is-view');
      bodyEle.classList.remove('is-fixed');
    } else {
      hamburgerTrigger.classList.add('is-active');
      headerNav.classList.add('is-view');
      bodyEle.classList.add('is-fixed');
    }
  })
}